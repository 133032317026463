import React from 'react';

import { Education } from '~components/Education/Education';
import { ListSection } from '~components/ListSection/ListSection';

import { DownloadSection } from './DownloadSection';
import { Experience } from './Experience';
import { SKILLS_LIST } from './ResumePage.data';
import './ResumePage.scss';

export const ResumePage = () => (
	<div className="resume" id="container">
		<h1>Resume</h1>

		<DownloadSection />

		<Experience />

		<ListSection list={SKILLS_LIST} title="Skills" />

		<Education />
	</div>
);
